<template>
  <div class="progress-bar-container">
    <div
      class="progress-bar"
      :style="{ width: progress + '%' }"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
.progress-bar-container {
  width: 100%;
  background: #D9D9D9;;
  height: 4px;
}

.progress-bar {
  height: 100%;
  background: #0041A6;
  transition: width 0.3s ease;
}
</style>