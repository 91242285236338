import Swiper from 'swiper';

export function dashBoardSwiper() {
  new Swiper('.top-swiper', {
    loop: false,
    simulateTouch: false,
    spaceBetween: 20,
    slidesPerView: 1,
    navigation: {
      nextEl: '.js-events-swiper-next',
      prevEl: '.js-events-swiper-prev'
    },
    breakpoints: {
      576: {
        slidesPerView: 1,
        centeredSlides: false
      },
      992: {
        slidesPerView: 3
      },
      1200: {
        spaceBetween: 30,
        slidesPerView: 3
      }
    }
  });
}

export function distroSwiper() {
  new Swiper('.distro-swiper', {
    loop: false,
    simulateTouch: false,
    spaceBetween: 20,
    slidesPerView: 1,
    navigation: {
      nextEl: '.js-events-swiper-next-distro',
      prevEl: '.js-events-swiper-prev-distro'
    },
    breakpoints: {
      576: {
        slidesPerView: 1,
        centeredSlides: false
      },
      992: {
        slidesPerView: 3
      },
      1200: {
        spaceBetween: 30,
        slidesPerView: 3
      }
    }
  });
}
