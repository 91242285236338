<template>
  <button
    type="button"
    class="btn btn-link text-base font-normal normal-case underline hover:no-underline"
    @click="uncheckAll"
  >
    Unsubscribe from all
  </button>
</template>

<script>
export default {
  methods: {
    uncheckAll() {
      let cb = document.querySelectorAll('.uncheck');

      cb.forEach(function (input) {
        input.checked = false;
      });
    }
  }
};
</script>
