/* global require:true */

import '../stylesheets/app.scss';
require.context('../images', true);

import '../initializers/turbolinks.js';
import googleTracking from '../initializers/google-tracking.js';
import Vue from 'vue/dist/vue.esm';
import Rails from 'rails-ujs';
import cookies from 'vue-cookies';
import VTooltip from 'v-tooltip';

import cookieNotice from '../components/cookie-notice.js';
import uncheckBtn from '../components/uncheck-button';
import infographicModal from '../components/infographic-modal';
import accordion from '../components/accordion';
import readMore from '../components/read-more';
import flashMessage from '../components/flash-message.js';
import CustomSelect from '../components/custom-select';
import SearchList from '../components/search-list';
import DatePicker from 'vue2-datepicker';
import Dropdown from '../components/dropdown';
import ReactorsFilters from '../components/reactors-filters.js';
import SimpleMultiSelect from '../components/simple-multiselect.vue';
import ReactorModal from '../components/reactor-modal.vue';
import CustomSlide from '../components/distribution-slide.vue';
import LinearProgress from '../components/linear-progress.vue';
import ModalTable from '../components/pop-up-table.vue';
import { dashBoardSwiper, distroSwiper } from '../components/swiper';

let currentAdmin = false;

Vue.use(VTooltip);
Vue.use(cookies);

Vue.component('cookie-notice', cookieNotice);
Vue.component('uncheck-button', uncheckBtn);
Vue.component('infographic-modal', infographicModal);
Vue.component('flash-message', flashMessage);
Vue.component('accordion', accordion);
Vue.component('read-more', readMore);
Vue.component('custom-select', CustomSelect);
Vue.component('date-picker', DatePicker);
Vue.component('search-list', SearchList);
Vue.component('reactors-filters', ReactorsFilters);
Vue.component('dropdown', Dropdown);
Vue.component('simple-multiselect', SimpleMultiSelect);
Vue.component('reactor-modal', ReactorModal);
Vue.component('distribution-slide', CustomSlide);
Vue.component('linear-progress', LinearProgress);
Vue.component('modal-table', ModalTable);


Vue.component('slot-item', () =>
  currentAdmin
    ? import('../components/admin/slot-item')
    : import('../components/admin/empty-component')
);
Vue.component('news-slots', () =>
  currentAdmin
    ? import('../components/admin/news-slots')
    : import('../components/admin/empty-component')
);
Vue.component('page-views', () =>
  currentAdmin
    ? import('../components/admin/page-views')
    : import('../components/admin/empty-component')
);

Rails.start();
googleTracking();

let vueApp;

document.addEventListener('turbolinks:load', () => {
  currentAdmin = document.body.dataset.admin;

  vueApp = new Vue({
    el: '#vueApp',

    data() {
      return {
        nav: {
          showSearch: false,
          showMobileNav: false,
        },
        plus_btn: 'To read join NucNet',
      };
    },

    methods: {
      showSearch() {
        this.nav.showSearch = true;
        document.querySelector('.search-form input[name="query"]').focus();
      },
    },

    mounted() {
      dashBoardSwiper();
      distroSwiper();
    }
  });
});

document.addEventListener('turbolinks:visit', function teardown() {
  if (vueApp) {
    vueApp.$destroy();
  }
});
