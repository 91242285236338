<template>
  <div>
    <slot v-if="!isOpen" name="title" />

    <transition
      name="contents"
      @enter="enter"
      @after-enter="afterEnter"
      @leave="leave"
    >
      <div v-show="isOpen" :id="id" ref="content">
        <slot />
      </div>
    </transition>
    <button
      @click="toggleAccordion()"
      class="focus:outline-none pb-4"
      :aria-expanded="isOpen"
      :aria-controls="id"
      style="outline: none"
    >
      <span
        class="font-semibold hover:underline"
        style="color: #0041a6; font-size: 20px"
        >{{ title }}</span
      >
    </button>
  </div>
</template>

<script>
export default {
  props: {
    readMoreLabel: { required: false, type: String, default: 'Read More' },
    readLessLabel: { required: false, type: String, default: 'Read Less' },
  },

  data() {
    return {
      isOpen: false,
    };
  },

  computed: {
    title() {
      return this.isOpen ? 'Read Less' : 'Read More';
    },
    id() {
      return this._uid;
    },
  },

  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },

    enter(element) {
      const width = getComputedStyle(element).width;

      element.style.width = width;
      element.style.position = 'absolute';
      element.style.visibility = 'hidden';
      element.style.height = 'auto';

      const height = getComputedStyle(element).height;

      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;

      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = 'auto';
    },
    leave(element) {
      const height = getComputedStyle(element).height;

      element.style.height = height;

      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = 0;
      });
    },
  },
};
</script>

<style scoped>
.contents-enter-active,
.contents-leave-active {
  transition: height 0.2s ease;
  overflow: hidden;
}

.contents-enter,
.contents-leave-to {
  height: 0;
}
</style>
