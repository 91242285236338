<template>
  <div>
    <h1 v-if="title.length > 1" class="table-title">Distribution by {{ title }}</h1>
    <table class="reactors-table table-auto mt-8">
      <thead class="reactors-head">
        <tr>
          <th class="text-center">#</th>
          <th>{{ capitalizeWords }}</th>
          <th class="pl-0">Number of reactors</th>
        </tr>
      </thead>
      <tbody class="reactors-body">
        <tr v-for="(reactor, index) in paginatedData" :key="index" :class="index % 2 === 0 ? 'gray' : ''"
          @click="handleRowClick(reactor.name, reactor.value)">
          <td>{{ (currentPage - 1) * perPage + index + 1 }}</td>
          <td class="text-left pl-6">{{ reactor.name }}</td>
          <td>
            <LinearProgress :progress="reactor.percentage || 0" />
          </td>
          <td class="pl-4 pr-12">
            {{ reactor.reactors_count }}
          </td>
        </tr>
        <tr v-if="data.length === 0">
          <td colspan="3">No results found.</td>
        </tr>
      </tbody>
    </table>

    <div class="reactors-pagination-container" v-if="data.length > 10">
      <Pagination v-model="currentPage" :perPage="perPage" :length="data.length" />
    </div>
  </div>
</template>

<script>
import Pagination from './Pagination.vue';
import LinearProgress from './linear-progress.vue';

const filterOptionsMap = {
  size: 'sizes',
  electric_output: 'electricOutputs',
  thermal_output: 'thermalOutputs',
  country_of_origin: 'countries',
  fuel_class: 'fuelClasses'
}

export default {
  components: {
    Pagination,
    LinearProgress
  },

  props: {
    data: {
      type: Array,
      required: true
    },
    currentPage: {
      type: Number,
      default: 1
    },
    perPage: {
      type: Number,
      default: 10
    },
    title: {
      type: String,
      required: false,
      default: ''
    }
  },

  data() {
    const params = new URLSearchParams(window.location.search);

    return {
      filterOptionsMap: filterOptionsMap,
      countries: params.getAll('countries[]'),
      sizes: params.getAll('sizes[]'),
      electricOutputs: params.getAll('electricOutputs[]'),
      fuelClasses: params.getAll('fuelClasses[]'),
      thermalOutputs: params.getAll('thermalOutputs[]'),
      models: params.getAll('models[]'),
      spectrums: params.getAll('spectrums[]'),
      deploymentMediums: params.getAll('deploymentMediums[]'),
      moderators: params.getAll('moderators[]'),
      coolings: params.getAll('coolings[]'),
      fuelTypes: params.getAll('fuelTypes[]'),
      outletTemperatures: params.getAll('outletTemperatures[]'),
      designStatuses: params.getAll('designStatuses[]'),
      isEditing: false
    }
  },

  computed: {
    filters() {
      const params = new URLSearchParams();

      if (this.query && this.query.trim()) {
        params.set('query', this.query);
      }

      this.countries.forEach((country) =>
        params.append('countries[]', country)
      );
      this.models.forEach((model) => params.append('models[]', model));
      this.sizes.forEach((size) => params.append('sizes[]', size));
      this.electricOutputs.forEach((output) =>
        params.append('electricOutputs[]', output)
      );
      this.fuelClasses.forEach((fuelClass) =>
        params.append('fuelClasses[]', fuelClass)
      );

      this.thermalOutputs.forEach((fuelClass) =>
        params.append('thermalOutputs[]', fuelClass)
      );

      this.spectrums.forEach((spectrums) =>
        params.append('spectrums[]', spectrums)
      );

      this.deploymentMediums.forEach((deploymentMediums) =>
        params.append('deploymentMediums[]', deploymentMediums)
      );

      this.moderators.forEach((moderator) =>
        params.append('moderators[]', moderator)
      );

      this.coolings.forEach((cooling) =>
        params.append('coolings[]', cooling)
      );

      this.fuelTypes.forEach((fuelType) =>
        params.append('fuelTypes[]', fuelType)
      );

      this.outletTemperatures.forEach((outletTemperature) =>
        params.append('outletTemperatures[]', outletTemperature)
      );

      this.designStatuses.forEach((designStatus) =>
        params.append('designStatuses[]', designStatus)
      );

      return params.toString();
    },

    fullUrl() {
      return `/?${this.filters}`;
    },


    paginatedData() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.data.slice(start, end);
    },

    capitalizeWords() {
      return this.title
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    },

    param() {
      return this.title
        .split(' ')
        .join('_');
    }
  },

  methods: {
    launchFetch() {
      history.pushState({}, '', this.fullUrl);
      this.reloadPageWithTurbolinks();
    },

    handlePageChange(page) {
      this.$emit('update:currentPage', page);
    },

    reloadPageWithTurbolinks() {
      let scrollPosition;

      scrollPosition = [window.scrollX, window.scrollY];
      Turbolinks.visit(window.location.toString(), { action: 'replace' });

      document.addEventListener('turbolinks:load', function () {
        if (scrollPosition) {
          window.scrollTo.apply(window, scrollPosition);
          scrollPosition = null;
        }
      });
    },

    handleRowClick(reactorName, key) {
      this.isEditing = false;
      const params = new URLSearchParams(window.location.search);

      params.set(filterOptionsMap[this.param], key);
      this.sizes = [];
      const newUrl = `${window.location.pathname}?${params.toString()}`;

      history.pushState({}, '', newUrl);
      this.reloadPageWithTurbolinks();
    }
  },
  watch: {
    filters() {
      if (this.query && this.query.trim()) {
        setTimeout(() => {
          this.launchFetch();
        }, 2000);
      } else {
        if (!this.isEditing) {
          this.launchFetch();
        }
      }
    },
  },
};
</script>

<style scoped>
.gray {
  background-color: #f0f0f0;
}

.table-title {
  @apply font-primary text-left text-xl;
  font-style: normal;
  font-weight: 600;
}
</style>
