<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container p-6">
          <div class="relative">
            <div
              class="modal-close-button font-sans font-bold reactor-modal-close-btn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                class="reactor-modal-close-btn"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  class="reactor-modal-close-btn"
                  d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z"
                  fill="#979797"
                />
              </svg>
            </div>
          </div>
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  methods: {
    closeModal(event) {
      this.isEditing = false;
     
      event.preventDefault()
    },
  },
};
</script>

<style scoped>

.reactor-modal-close-btn:hover {
  cursor: pointer;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  overflow: visible;
}

.modal-container {
  display: inline-block;
  position: relative;
  width: 80%;
  max-width: 1200px;
  min-height: 200px;
  height: auto;
  max-height: 90%;
  overflow-y: auto;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #eaecf0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  overflow: visible;
}

.modal-default-button {
  float: right;
}

.modal-close-button {
  color: #000;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 10px;
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  padding: 10px;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.your-dropdown-class {
  position: absolute;
  z-index: 9999;
}
</style>
