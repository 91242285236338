export default function () {
  document.addEventListener('turbolinks:load', function (event) {
    if (!window.dataLayer) {
      return;
    }

    window.dataLayer.push({
      event: 'pageView',
      virtualUrl: event.data.url
    });

    if (typeof gtag === 'function') {
      window.gtag('config', 'UA-26624651-1', {
        page_location: event.data.url
      });
    }
  });
}
