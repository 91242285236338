<template>
  <div>
    <div @click="hadnleOpen()" class="hover-pill">
      View List 
    </div>

    <div name="modal" v-show="isEditing" @close="isEditing = false">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container p-6">
            <div class="relative">
              <button
                class="modal-close-button font-sans font-bold"
                @click="isEditing = false"
              >
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="48" height="48" rx="24" transform="matrix(-1 0 0 1 48 0)" fill="#EFF5FF"/>
                  <path d="M31 18.41L29.59 17L24 22.59L18.41 17L17 18.41L22.59 24L17 29.59L18.41 31L24 25.41L29.59 31L31 29.59L25.41 24L31 18.41Z" fill="#0041A6"/>
                </svg>

              </button>
            </div>
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Table from './pop-up-table.vue'; 

export default {

  comments: {
    Table
  },

  props: {
    data: Array // Data to display in the table
  },

  data() {
    return {
      isEditing: false
    };
  },
  methods: {
    hadnleOpen() {
      this.isEditing = true
    }
  },
  watch: {
    isEditing(value) {
      if(value) {
        Array.from(document.querySelectorAll('.custom-swiper-container')).forEach((el) => {
        el.classList.add('change-transform')
        })
        Array.from(document.querySelectorAll('.swiper-wrapper')).forEach((el) => {
          el.classList.add('change-transform')
        })
      }
      else {
        Array.from(document.querySelectorAll('.custom-swiper-container')).forEach((el) => {
        el.classList.remove('change-transform')
        })
        Array.from(document.querySelectorAll('.swiper-wrapper')).forEach((el) => {
          el.classList.remove('change-transform')
        })
      }
    }
  }
};
</script>
<style scoped lang="scss">
  .hover-pill {
    @apply py-1 px-3 font-primary font-semibold text-sm;
    color: #0041A6;
    border-radius: 32px;
    background: #EFF5FF;

    &:hover {
      cursor: pointer;
      border-radius: 32px;
      background: #DFEBFF;
    }
  }
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .7);
    display: table;
    transition: opacity .3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }

  .modal-container {
    @apply p-20;
  
    display: inline-block;
    position: relative;
    width: 100%;
    max-width: 1200px;
    max-height: 95vh;
    overflow-y: auto;
    margin: 0px auto;
    background-color: #fff;
    border-radius: 12px;
    border: 1px solid #EAECF0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;

    @screen md {
      width: 60%;
    }
  }

  .modal-default-button {
    float: right;
  }

  .modal-close-button {
    color: #000;
    display: flex;
    align-items: center;
    position: absolute;
    top: -20px;
    right: 0px;
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;
    padding: 10px;
  }

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
</style>