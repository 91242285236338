<template>
  <div class="pagination-container">
    <div>
      <button @click="goTo(page - 1)" type="button" class="arrow " :disabled="isFirstPage">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M15.8334 9.99999H4.16669M4.16669 9.99999L10 15.8333M4.16669 9.99999L10 4.16666" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path></svg>
        Previous
      </button>
    </div>
    <div class="pages">
      <button
        v-for="p in leftPages"
        :key="p"
        @click="goTo(p)"
        :class="{ active: p === page }"
        type="button"
      >
        {{ p }}
      </button>
      <span v-if="rightPages.length" class="separator">...</span>
      <button
        v-for="p in rightPages"
        :key="p"
        @click="goTo(p)"
        :class="{ active: p === page }"
        type="button"
      >
        {{ p }}
      </button>
    </div>
    <div class="sm:hidden">{{ page }}/{{ totalPages }}</div>
    <div>
      <button @click="goTo(page + 1)" type="button" class="arrow " :disabled="isLastPage">
        Next
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M4.16669 9.99999H15.8334M15.8334 9.99999L10 4.16666M15.8334 9.99999L10 15.8333" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path></svg>
      </button>
    </div>
  </div>
</template>

<script>
const MIN_PAGES_TO_PAGINATE = 5;

export default {
  props: {
    value: { required: true, type: Number },
    length: { required: true, type: Number },
    perPage: { required: true, type: Number }
  },

  computed: {
    page: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },

    totalPages() {
      return Math.ceil((this.length * 1.0) / this.perPage);
    },

    isFirstPage() {
      return this.page === 1;
    },

    isLastPage() {
      return this.page === this.totalPages;
    },

    isCurrentPageAfterMiddle() {
      return this.page > this.totalPages / 2;
    },

    leftPages() {
      if (this.totalPages < MIN_PAGES_TO_PAGINATE) {
        return this.allPages;
      }

      if (this.isCurrentPageAfterMiddle) {
        return [1];
      }

      return this.sliceNeighboringPages();
    },

    rightPages() {
      if (this.totalPages < MIN_PAGES_TO_PAGINATE) {
        return [];
      }

      if (!this.isCurrentPageAfterMiddle) {
        return [this.totalPages];
      }

      return this.sliceNeighboringPages();
    },

    allPages() {
      return Array.from({ length: this.totalPages }, (_, i) => i + 1);
    }
  },

  methods: {
    goTo(page) {
      this.page = page;
    },

    sliceNeighboringPages() {
      const totalSlice = 5;
      const halfSlice = Math.ceil((totalSlice * 1.0) / 2);

      if (this.page < halfSlice) {
        return this.allPages.slice(0, totalSlice);
      }
      if (this.page > this.totalPages - halfSlice) {
        return this.allPages.slice(this.totalPages - totalSlice, this.totalPages);
      }

      return this.allPages.slice(this.page - halfSlice, this.page + halfSlice - 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.pagination-container {
  @apply text-sm text-primary;
  @apply flex justify-between mt-4 items-center;
  @apply select-none;
}

.separator {
  @apply flex items-center;
  @apply px-3;
  color: #808080;
}

.pages {
  @apply flex;
  @apply w-1/2 justify-center;

  button {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    color: #344054;

    &.active {
      border-radius: 8px;
      background: var(--Gray-50, #f9fafb);
    }
  }
}

.arrow {
  @apply justify-center items-center text-sm font-semibold;
  display: flex;
  padding: 8px 14px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, .05);
  color: #344054;

  &:disabled {
    @apply text-gray;

  }
}
</style>
