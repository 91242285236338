import axios from 'axios';
import camelize from 'lodash/camelCase';

const filterOptions = [
  'countries',
  'models',
  'sizes',
  'electricOutputs',
  'fuelClasses',
  'tags',
  'thermalOutputs',
  'spectrums',
  'deploymentMediums',
  'moderators',
  'coolings',
  'fuelTypes',
  'outletTemperatures'
]

const filterOptionsInMoreModal = [
  'thermalOutputs',
  'spectrums',
  'deploymentMediums',
  'moderators',
  'coolings',
  'fuelTypes',
  'outletTemperatures',
  'designStatuses',
  'fuelClasses',
  'undergoing_licensing',
  'pilot_site'
]

export default {
  data() {
    const params = new URLSearchParams(window.location.search);

    return {
      query: params.get('query'),
      countries: params.getAll('countries[]'),
      models: params.getAll('models[]'),
      sizes: params.getAll('sizes[]'),
      electricOutputs: params.getAll('electricOutputs[]'),
      fuelClasses: params.getAll('fuelClasses[]'),
      thermalOutputs: params.getAll('thermalOutputs[]'),
      spectrums: params.getAll('spectrums[]'),
      deploymentMediums: params.getAll('deploymentMediums[]'),
      moderators: params.getAll('moderators[]'),
      coolings: params.getAll('coolings[]'),
      fuelTypes: params.getAll('fuelTypes[]'),
      outletTemperatures: params.getAll('outletTemperatures[]'),
      designStatuses: params.getAll('designStatuses[]'),
      undergoing_licensing: params.getAll('undergoing_licensing[]'),
      pilot_site: params.getAll('pilot_site[]'),
      isEditing: false
    };
  },

  watch: {
    filters() {
      if (this.query && this.query.trim()) {
        setTimeout(() => {
          this.launchFetch();
        }, 2000);
      } else {
        if(!this.isEditing) {
          this.launchFetch();
        }
      }
    },
  },

  computed: {
    filters() {
      const params = new URLSearchParams();

      if (this.query && this.query.trim()) {
        params.set('query', this.query);
      }

      this.countries.forEach((country) =>
        params.append('countries[]', country)
      );
      this.models.forEach((model) => params.append('models[]', model));
      this.sizes.forEach((size) => params.append('sizes[]', size));
      this.electricOutputs.forEach((output) =>
        params.append('electricOutputs[]', output)
      );
      this.fuelClasses.forEach((fuelClass) =>
        params.append('fuelClasses[]', fuelClass)
      );

      this.thermalOutputs.forEach((fuelClass) =>
        params.append('thermalOutputs[]', fuelClass)
      );

      this.spectrums.forEach((spectrums) =>
        params.append('spectrums[]', spectrums)
      );

      this.deploymentMediums.forEach((deploymentMediums) =>
        params.append('deploymentMediums[]', deploymentMediums)
      );

      this.moderators.forEach((moderator) =>
        params.append('moderators[]', moderator)
      );
      
      this.coolings.forEach((cooling) =>
        params.append('coolings[]', cooling)
      );
  
      this.fuelTypes.forEach((fuelType) =>
        params.append('fuelTypes[]', fuelType)
      );

      this.outletTemperatures.forEach((outletTemperature) =>
        params.append('outletTemperatures[]', outletTemperature)
      );

      this.designStatuses.forEach((designStatus) =>
        params.append('designStatuses[]', designStatus)
      );

      this.undergoing_licensing.forEach((undergoingLicensing) =>
        params.append('undergoing_licensing[]', undergoingLicensing) 
      );

      this.pilot_site.forEach((pilotSite) =>
        params.append('pilot_site[]', pilotSite)
      );

      return params.toString();
    },

    fullUrl() {
      return `/?${this.filters}`;
    },

    selectedFilterCount() {
      return filterOptionsInMoreModal.reduce((count, filter) => {
        let value = this[camelize(filter)];
        if (value && (Array.isArray(value) ? value.length : true)) {
          count++;
        }
        return count;
      }, 0);
    },
  },

  methods: {
    handleClick(event) {
      if(event.target.classList.contains('reactor-modal-close-btn')) {
        this.isEditing = false;
      }
    },
    getSelectedCount(filter) {
      let value = this[camelize(filter)];
      if (Array.isArray(value)) {
        return value.length;
      }
      return value ? 1 : 0;
    },
    removeTag(type, value) {
      console.log(type)
      console.log(value)
      this[type] = this[type].filter((item) => item !== value);
      this.refreshPage();

      this.launchFetch();
    },

    applyModalFilters() {
      this.isEditing = false;
      this.refreshPage();
      this.launchFetch();
    },

    resetFilters() {
      this.isEditing = false; 
      this.thermalOutputs = [];
      this.spectrums = [];
      this.deploymentMediums = [];
      this.moderators = [];
      this.coolings = [];
      this.fuelTypes = [];
      this.outletTemperatures = [];
      this.designStatuses = [];

      this.refreshPage();
      this.launchFetch();
    },

    launchFetch() {
      history.pushState({}, '', this.fullUrl);
      this.reloadPageWithTurbolinks();
    },
    async fetchResults() {
      const { data } = await axios.get(this.fullUrl, {
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      });

      document.querySelector('#reactors').innerHTML = data;
    },

    refreshPage() {
      const url = [window.location.pathname, this.filters].join('?');
      Turbolinks.visit(url);
    },

    // Turbolinks realod page with scroll position method
    // Took the idea from: https://stackoverflow.com/questions/37358652/how-to-refresh-a-page-with-turbolinks#answer-44353026
    reloadPageWithTurbolinks() {
      let scrollPosition;

      scrollPosition = [window.scrollX, window.scrollY];
      Turbolinks.visit(window.location.toString(), { action: 'replace' });

      document.addEventListener('turbolinks:load', function () {
        if (scrollPosition) {
          window.scrollTo.apply(window, scrollPosition);
          scrollPosition = null;
        }
      });
    },
  },
};
