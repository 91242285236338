<template>
  <div>
    <figure
      class="infographic-img"
      @click="isModalVisible = true"
    >
      <slot>click</slot>
    </figure>
    <transition name="fade">
      <div
        v-if="isModalVisible"
        class="modal-backdrop"
        role="dialog"
      >
        <button
          type="button"
          class="modal-close-btn"
          aria-label="Close modal"
          @click="isModalVisible = false"
        >
          <svg
            width="32"
            height="32"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            fill="#FFF"
          >
            <path d="M15 2.41L13.59 1 8 6.59 2.41 1 1 2.41 6.59 8 1 13.59 2.41 15 8 9.41 13.59 15 15 13.59 9.41 8z" />
          </svg>
        </button>

        <div class="infographic-img-modal-wrapper">
          <slot name="body" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isModalVisible: false
    };
  }
};
</script>
