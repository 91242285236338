<template>
  <div>
    <div @click="isOpen = true">
      <slot name="trigger" :isOpen="isOpen"></slot>
    </div>

    <div @click="isOpen = !isOpen">
      <slot name="show" :isOpen="isOpen"></slot>
    </div>

    <transition name="accordion" @enter="enter" @after-enter="leave">
      <div v-if="isOpen" class="accordion-body">
        <slot name="body"/>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    enter(el) {
      el.style.height = el.scrollHeight + 'px'
    },
    leave(el) {
      if(!this.isOpen) {
        el.style.height = '0'
      }
    }
  }
};
</script>
<style scoped>
.accordion-body {
  transition: height 0.2s ease-in;
}
</style>