/* global $cookies */

export default {
  data() {
    return {
      visible: false
    };
  },
  mounted() {
    const accepted = $cookies.get('cookie-consent') || false;

    if (!accepted) {
      this.visible = true;
    }
  },
  methods: {
    accept() {
      $cookies.set('cookie-consent', true, new Date('01 Jan 2037 00:00:00 UTC'));
      this.visible = false;
    }
  }
};
