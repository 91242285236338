export default {
  data() {
    return {
      visible: true
    };
  },
  mounted() {
    setTimeout(()=> {
      this.visible = false;
    }, 5000);
  },
  methods: {
    close() {
      this.visible = false;
    }
  }
};
