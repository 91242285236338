<template>
  <multiselect
    v-model="selected"
    :options="options"
    :class="{ 'remove-tags' : selected.length }"
    multiple
    searchable
    track-by="value"
    label="name"
    :placeholder="placeholder"
  />
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  components: { Multiselect },

  props: {
    options: { type: Array, required: true },
    placeholder: { type: String, default: 'Select option' },
    value: { type: Array, default: () => [] },
  },

  data() {
    return {
      countries: [],
    };
  },

  computed: {
    selected: {
      get() {
        return this.value.map(
          (v) =>
            this.options.find((o) => o.value === v) || { value: v, name: v }
        );
      },
      set(value) {
        this.$emit(
          'input',
          value.map((v) => v.value)
        );
      },
    },
  },
};
</script>

<style>
.multiselect {
  border: none !important;
}
.multiselect__tags {
  border: none !important;
  background: #f5f5f5;
}
.multiselect__placeholder {
  color: black;
}
.multiselect__input {
  background: none;
}
.multiselect__select:before {
  border-color: #000 transparent transparent;
}
</style>
